interface Base {
  Id: number;
  Name: string;
}

export interface WorkOperation extends Base {}

export interface Project extends Base {
  Number: number;
  Token: string;
}

export enum NewBookingType {
  Coming = 1,
  Leaving = 2,
  LeavingWithReason = 3,
  ProjectStart = 4,
  ProjectEnd = 5,
  ComingWithReason = 6,
  ProjectStartWithNewProject = 99
}

export interface IScanMetadata {}

export interface SchmerITMetadata extends IScanMetadata {
  ApiToken: string;
  ServerUrl?: string;
  Username?: string;
  Password?: string;
}

export interface TimeCard10Metadata extends IScanMetadata {
  BookingType: NewBookingType;
  PersonId?: number;
  AbsenceTypeToken?: string,
  ProjectNumber?: number,
  WorkOperationNumber?: number,
  Comment?: string;
};