import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Buffer } from "buffer";
import QRCode from "qrcode";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { IScanMetadata, NewBookingType } from "./models/timecard10";
// import { API, AppData } from "./api";

type QRCodeType = "TimeCard10" | "SchmerIT";
type QRCodeImage = {
  name: string;
  data: string;
};
const imageSize = 256;

const printDiv = (elem: HTMLElement) => {
  const innerHTML = elem.innerHTML;
  const fullHTML = `<html>
    <head>
      <title>QR Codes</title>
      <style>body { height:100vh;
       width:100vw;
       text-align:center;
       display:flex;
       flex-direction:column;
       align-items:center;
       justify-content:center;
       margin:0;
       padding:15px;
       color:#000;
       font-family:Arial; }
       div.qr-code-wrapper{margin:25px 0;border:1px solid #000;}
       div.qr-code-wrapper h2{margin:0 0 15px;border-bottom:1px solid #000;}</style>
    </head>
    <body>${innerHTML}</body>
    </html>`;
  const w = window.open("", "_blank");
  w!.document.documentElement.innerHTML = fullHTML;
  setTimeout(() => {
    w?.print();
  }, 100);
};

const BookingTypes = [
  { text: "Kommen", value: NewBookingType.Coming },
  { text: "Kommen mit Grund", value: NewBookingType.ComingWithReason },
  { text: "Gehen", value: NewBookingType.Leaving },
  { text: "Gehen mit Grund", value: NewBookingType.LeavingWithReason },
  { text: "Projektstart", value: NewBookingType.ProjectStart },
  { text: "Projektende", value: NewBookingType.ProjectEnd },
  { text: "Projektstart mit Projektanlage", value: NewBookingType.ProjectStartWithNewProject }
];

const evaluateIsValid = (qrCodeType: QRCodeType,
  bookingType: number,
  absenceTypeToken?: string,
  projectName?: string,
  projectAbbreviation?: string,
  projectNumber?: number,
  projectDescription?: string,
  workOperationNumber?: number,
  apiToken?: string) => {
  if (qrCodeType === "TimeCard10") {
    if ([NewBookingType.Coming, NewBookingType.Leaving].includes(bookingType)) {
      return true;
    } else if ([NewBookingType.LeavingWithReason,NewBookingType.ComingWithReason].includes(bookingType)) {
      return !!absenceTypeToken;
    } else if ([NewBookingType.ProjectStart,NewBookingType.ProjectEnd].includes(bookingType)) {
      return !!projectNumber && !!workOperationNumber;
    } else if ([NewBookingType.ProjectStartWithNewProject].includes(bookingType)) {
      return !!projectName && !!projectAbbreviation && !!projectNumber;
    }
  }
  else if (qrCodeType === "SchmerIT")
    return apiToken?.length === 36;
}

function App() {
  // const [api, setApi] = useState<API>(new API({
  //   server: 'https://timecard10.schmer.it',
  //   username: '',
  //   password: ''
  // }));
  // const [appData, setAppData] = useState<AppData>();
  const [qrCodeType, setQRCodeType] = useState<QRCodeType>("TimeCard10");
  const [qrCodeImages, setQrCodeImages] = useState<QRCodeImage[]>([]);
  const [apiToken, setApiToken] = useState<string>("");
  const [serverUrl, setServerUrl] = useState<string>();
  const [bookingType, setBookingType] = useState<NewBookingType>(NewBookingType.ProjectStart);
  const [absenceTypeToken, setAbsenceTypeToken] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [projectName, setProjectName] = useState<string>();
  const [projectAbbreviation, setProjectAbbreviation] = useState<string>();
  const [projectNumber, setProjectNumber] = useState<number>();
  const [projectDescription, setProjectDescription] = useState<string>();
  const [workOperationNumber, setWorkOperationNumber] = useState<number>();
  const [comment, setComment] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>();

  const elemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsValid(evaluateIsValid(qrCodeType,
      bookingType,
      absenceTypeToken,
      projectName,
      projectAbbreviation,
      projectNumber,
      projectDescription,
      workOperationNumber,
      apiToken));
  }, [qrCodeType, bookingType, apiToken, absenceTypeToken, projectName, projectAbbreviation, projectNumber, projectDescription, workOperationNumber, comment]);

  // useEffect(() => {
  //   api.fetchData()
  //     .then(res => setAppData(res))
  //     .catch(err => {
  //       alert("Fehler beim Laden der Daten von TimeCard10. Bitte überprüfen Sie Ihre Einstellungen.")
  //     });
  // }, []);

  useEffect(() => {
    console.log(qrCodeImages);
  }, [qrCodeImages]);

  const createQRCode = async (name: string, dataJson: IScanMetadata) => {
    const dataJsonString = JSON.stringify(dataJson);
    const dataString = `schmerittimetracking://${Buffer.from(dataJsonString).toString("base64")}`;

    return new Promise<QRCodeImage>((resolve, reject) => {
      QRCode.toDataURL(
        dataString,
        {
          width: imageSize,
        },
        (error, res) => {
          if (error)
            reject("Fehler beim Erstellen des QR Codes: " + error.message);
          else
            resolve({
              name: name,
              data: res,
            });
        }
      );
    });
  };

  const createSettingsInfo = async () => {
    const dataJson = {
      type: qrCodeType,
      metadata: {
        ApiToken: apiToken,
        ServerUrl: serverUrl,
        Username: username,
        Password: password
      },
    };
    return createQRCode("Einstellungen", dataJson);
  };

  const createBookingInfo = async () => {
    const name = BookingTypes.find((t) => t.value === bookingType)!.text;

    switch (bookingType) {
      case NewBookingType.Coming:
      case NewBookingType.Leaving:
      case NewBookingType.ComingWithReason:
      case NewBookingType.LeavingWithReason:
        return createQRCode(name, {
          type: "TimeCard10",
          metadata: {
            BookingType: bookingType,
            AbsenceTypeToken: absenceTypeToken,
            Comment: comment,
          }
        });
      case NewBookingType.ProjectStartWithNewProject:
        return createQRCode(name, {
          type: "TimeCard10",
          metadata: {
            BookingType: NewBookingType.ProjectStart,
            ProjectName: projectName,
            ProjectAbbreviation: projectAbbreviation,
            ProjectNumber: projectNumber,
            ProjectDescription: projectDescription,
            WorkOperationNumber: workOperationNumber,
            Comment: comment,
          }    
        });
        case NewBookingType.ProjectStart:
        case NewBookingType.ProjectEnd:
          return createQRCode(name, {
            type: "TimeCard10",
            metadata: {
              BookingType: bookingType,
              ProjectNumber: projectNumber,
              WorkOperationNumber: workOperationNumber,
              Comment: comment,
            }    
          });
    }
  };

  const generate = async () => {
    if (!isValid) return;

    try {
      if (qrCodeType === "TimeCard10") {
        const qrCode = await createBookingInfo();
        qrCode && setQrCodeImages([qrCode]);
      } else if (qrCodeType === "SchmerIT") {
        setQrCodeImages([await createSettingsInfo()]);
      }

      setTimeout(() => {
        elemRef?.current?.scrollIntoView();
      }, 500);
    } catch (err: any) {
      alert(err?.message ?? err);
    }
  };

  return (
    <>
      <div className="header">
        <div className="img-wrapper">
          <img src="logo_timecard10.png" alt="timeCard10" />
        </div>
        <span className="desktop-only">
          QR Code Generator für die TimeCard10 QR-Code Zeiterfassung
        </span>
        <div className="img-wrapper">
          <a href="https://schmer.it" target="_blank" rel="noreferrer">
            <img
              src="logo_poweredby.png"
              alt="powered by Schmer.IT GmbH"
              style={{display: "none"}}
            />
          </a>
        </div>
      </div>
      <main>
        <div className="container">
          <h2>Eingaben</h2>
          <div className="content">
            <Form>
              <Form.Group className="mb-3" controlId="form.qrCodeType">
                <Form.Select
                  value={qrCodeType}
                  onChange={(ev) =>
                    setQRCodeType(ev.target.value as QRCodeType)
                  }
                >
                  <option value="TimeCard10">Zeiterfassung</option>
                  <option value="SchmerIT">Einstellungen</option>
                </Form.Select>
              </Form.Group>
              {qrCodeType === "SchmerIT" && (
                <>
                  <Form.Group className="mb-3" controlId="form.apiToken>">
                    <Form.Label>API Token:</Form.Label>
                    <Form.Control
                      as="input"
                      type="text"
                      minLength={36}
                      maxLength={36}
                      value={apiToken}
                      onChange={(ev) => setApiToken(ev.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="form.serverUrl>">
                    <Form.Label>
                      timeCard 10 Server Url (ohne https, z.B. timecard10.meinedomain.de):
                    </Form.Label>
                    <Form.Control
                      as="input"
                      type="text"
                      value={serverUrl}
                      onChange={(ev) => setServerUrl(ev.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="form.username>">
                    <Form.Label>
                      timeCard 10 Username:
                    </Form.Label>
                    <Form.Control
                      as="input"
                      type="text"
                      value={username}
                      onChange={(ev) => setUsername(ev.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="form.serverUrl>">
                    <Form.Label>
                      timeCard 10 Passwort:
                    </Form.Label>
                    <Form.Control
                      as="input"
                      type="password"
                      value={password}
                      onChange={(ev) => setPassword(ev.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </>
              )}
              {qrCodeType === "TimeCard10" && (
                <>
                  <Form.Group className="mb-3" controlId="form.bookingType">
                    <Form.Label>Art:</Form.Label>
                    <Form.Select
                      value={bookingType}
                      onChange={(ev) =>
                        setBookingType(parseInt(ev.target.value))
                      }
                    >
                      {BookingTypes.map((t) => (
                        <option key={t.value} value={t.value}>
                          {t.text}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  {[NewBookingType.ComingWithReason, NewBookingType.LeavingWithReason].includes(bookingType) && (
                    <>
                      <Form.Group className="mb-3" controlId="form.projectId">
                        <Form.Label>Grund (Token, z.B. KR oder UR):</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          value={absenceTypeToken}
                          onChange={(ev) =>
                            setAbsenceTypeToken(ev.target.value.trim())
                          }
                        ></Form.Control>
                      </Form.Group>
                    </>
                  )}
                  {[NewBookingType.ProjectStart, NewBookingType.ProjectEnd].includes(bookingType) && (
                    <>
                      <Form.Group className="mb-3" controlId="form.projectId">
                        <Form.Label>Projekt (Nummer):</Form.Label>
                        <Form.Control
                          as="input"
                          type="number"
                          min="0"
                          max="9999999"
                          step="1"
                          value={projectNumber}
                          onChange={(ev) => {
                            if (!ev.target.value) {
                              setProjectNumber(undefined);
                              return;
                            }
                            const val = parseInt(ev.target.value);
                            if (!isNaN(val)) {
                              setProjectNumber(val);
                            }
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="form.workOperationId"
                      >
                        <Form.Label>Tätigkeit (Nummer):</Form.Label>
                        <Form.Control
                          as="input"
                          type="number"
                          min="0"
                          max="9999999"
                          step="1"
                          value={workOperationNumber}
                          onChange={(ev) => {
                            if (!ev.target.value) {
                              setWorkOperationNumber(undefined);
                              return;
                            }
                            const val = parseInt(ev.target.value);
                            if (!isNaN(val)) {
                              setWorkOperationNumber(val);
                            }
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="form.comment">
                        <Form.Label>Buchungs-Kommentar:</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          value={comment}
                          onChange={(ev) =>
                            setComment(ev.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                    </>
                  )}
                  {[NewBookingType.ProjectStartWithNewProject].includes(bookingType) && (
                    <>
                      <Form.Group className="mb-3" controlId="form.projectName">
                        <Form.Label>Projekt-Bezeichnung:</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          value={projectName}
                          onChange={(ev) => 
                            setProjectName(ev.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="form.projectAbbreviation">
                        <Form.Label>Projektkürzel:</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          maxLength={10}
                          value={projectAbbreviation}
                          onChange={(ev) => 
                            setProjectAbbreviation(ev.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="form.projectNumber">
                        <Form.Label>Projektnummer:</Form.Label>
                        <Form.Control
                          as="input"
                          type="number"
                          value={projectNumber}
                          onChange={(ev) => {
                            if (!ev.target.value) {
                              setProjectNumber(undefined);
                              return;
                            }
                            const val = parseInt(ev.target.value);
                            if (!isNaN(val)) {
                              setProjectNumber(val);
                            }
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="form.projectDescription">
                        <Form.Label>Projekt-Beschreibung:</Form.Label>
                        <Form.Control
                          as="textarea"
                          type="text"
                          value={projectDescription}
                          onChange={(ev) =>
                            setProjectDescription(ev.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="form.workOperationId"
                      >
                        <Form.Label>Tätigkeit (Nummer):</Form.Label>
                        <Form.Control
                          as="input"
                          type="number"
                          min="0"
                          max="9999999"
                          step="1"
                          value={workOperationNumber}
                          onChange={(ev) => {
                            if (!ev.target.value) {
                              setWorkOperationNumber(undefined);
                              return;
                            }
                            const val = parseInt(ev.target.value);
                            if (!isNaN(val)) {
                              setWorkOperationNumber(val);
                            }
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="form.comment">
                        <Form.Label>Buchungs-Kommentar:</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          value={comment}
                          onChange={(ev) =>
                            setComment(ev.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                    </>
                  )}
                </>
              )}
            </Form>
          </div>
          <Button onClick={generate} disabled={!isValid}>Generieren</Button>
        </div>
        {qrCodeImages?.length > 0 && (
          <>
            <div className="container" ref={elemRef}>
              {qrCodeImages.map((i, index) => (
                <div key={index} className="qr-code-wrapper">
                  <h2>QR Code "{i.name}"</h2>
                  <div className="qr-code">
                    <img alt="qrcode" width={imageSize} src={i.data} />
                  </div>
                </div>
              ))}
            </div>
            <div className="container">
              <Button onClick={() => printDiv(elemRef.current!)}>
                Drucken
              </Button>
            </div>
          </>
        )}
      </main>
    </>
  );
}

export default App;
